import { FormState } from '@energiebespaarders/hooks/useForm';
import { ApolloError } from '@apollo/client';
import { findAddressFunction } from '../../types/generated/findAddressFunction';
import { FormAddress } from '.';

export const ALREADY_HAS_ADDRESS_ERROR = 'user already has a house with this address';
export const UNKNOWN_ADDRESS_ERROR = 'Unknown address';
export const NON_RESIDENTIAL_MESSAGE = 'Adres heeft geen woonfunctie';
export const NON_BUSINESS_MESSAGE = 'Adres heeft geen kantoorfunctie';

export const bagBusinessFunctions = [
  'kantoorfunctie',
  'industriefunctie',
  'winkelfunctie',
  'overige gebruiksfunctie',
];

export const getAddressFieldsError = (
  formState: FormState<FormAddress>,
  addressFunctionData: findAddressFunction | undefined,
  suffixes?: string[],
  networkError?: ApolloError,
  showPreExistingHouseError?: boolean,
  addressType: 'residential' | 'business' | 'all' = 'residential',
): string => {
  if (
    addressType === 'residential' &&
    addressFunctionData?.findAddressFunction &&
    addressFunctionData?.findAddressFunction !== 'woonfunctie'
  ) {
    return NON_RESIDENTIAL_MESSAGE;
  } else if (
    addressType === 'business' &&
    !bagBusinessFunctions.some(f => f !== addressFunctionData?.findAddressFunction)
  ) {
    return NON_BUSINESS_MESSAGE;
  }

  if (networkError?.message) {
    if (showPreExistingHouseError && networkError.message.includes(ALREADY_HAS_ADDRESS_ERROR)) {
      return 'Je hebt al een woning op dit adres toegevoegd';
    }
    if (networkError.message.includes(UNKNOWN_ADDRESS_ERROR)) {
      if (!formState.suffix?.value && Number(suffixes?.length) > 0) return 'Kies een toevoeging';
      return 'Adres niet gevonden';
    }
    console.error('Unknown error in getAddressFieldsError:', networkError?.message);
    return 'Er is iets misgegaan';
  }

  if (formState.zip.error) return formState.zip.error;
  if (formState.number.error) return formState.number.error;

  if (addressFunctionData?.findAddressFunction === null) {
    if (!formState.suffix?.value && Number(suffixes?.length) > 0) return 'Kies een toevoeging';
    return 'Geen gebouwfunctie gevonden';
  }

  return '';
};
